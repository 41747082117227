import {Component, OnInit, ViewChild, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {News} from "../../_models/news";
import {Event} from "../../_models/event";
import { ActualiteService } from 'src/app/_services/actualite.service';
import { EvenementService } from 'src/app/_services/evenement.service';
import { FlashService } from 'src/app/_services/flash.service';
import { BibService } from 'src/app/_services/bib.service';
import { InfoGeneraleService } from 'src/app/_services/info-generale.service';
import { SliderService } from 'src/app/_services/slider.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { InscriptionService } from 'src/app/_services/inscription.service';
import { GallerieService } from 'src/app/_services/gallerie.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GallerieComponent } from 'src/app/gallerie/gallerie.component';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import Swal from 'sweetalert2';
import { IntraMurosService } from 'src/app/_services/intra-muros.service';
import { leadingComment } from '@angular/compiler';
import { validators } from 'sitemap';
import { Title } from '@angular/platform-browser';
import slugify from 'slugify';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  masonryItems: any[] = [];
  actualites: any[] = [];
  actualitesIM: any;
  events: any;
  bibs: any;
  flashs: any;
  infos: any;
  sliders: any;
  errorMessage: string = "";
  retest: any;
  public options: NgxMasonryOptions = {
    itemSelector: '.grid-item',
    gutter: 10
  };

  introsOptions: { image: string, link: string, name: string, target: string }[] = [
    {image: 'assets/images/quickly/icone-famille.svg', link: 'https://parents.logiciel-enfance.fr/brece', name: 'Portail famille', target:'_blank'},
    {image: 'assets/images/quickly/icone-annuaire-assos.svg', link: '/mes-loisirs/associations', name: 'Annuaire des assos', target:'_self'},
    {image: 'assets/images/quickly/icone-mediatheque.svg', link: '/mes-loisirs/culture/mediatheque', name: 'Médiathèque', target:'_self'},
    {image: 'assets/images/quickly/icone-bib.svg', link: 'ma-commune/publications/bib', name: 'Le BIB', target:'_self'},
    {image: 'assets/images/quickly/icone-flash-info.svg', link: 'ma-commune/publications/flash-info', name: 'Le flash info', target:'_self'},
    {image: 'assets/images/quickly/icone-intramuros.svg', link: 'https://appli-intramuros.fr/', name: 'Télécharger INTRAMUROS', target:'_blank'},
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private actuService: ActualiteService, private eventService: EvenementService,
    private flashService: FlashService, private bibService: BibService, private infoService: InfoGeneraleService,
    private sliderService: SliderService, private formBuilder: FormBuilder, private inscriptionService: InscriptionService,
    private gallerieService: GallerieService, private modalService: NgbModal, private intraMurosService: IntraMurosService) { }
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;

  newsletterForm: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false
  };

  get f() { return this.newsletterForm.controls; }

  ngOnInit(): void {
    this.getActualites();
    this.getFlash();
    this.getBibs();
    this.getEvents();
    this.getInfos();
    this.getSliders();
    this.getGallerie();

    this.newsletterForm = this.formBuilder.group({
      nom: new FormControl('', Validators.required),
      prenom: new FormControl('', Validators.required),
      mail: new FormControl('', Validators.required),
      terms: new FormControl('', Validators.required),
    })

    //closing the modal
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('#btn-close-gallerie')?.addEventListener('click', () => {
        this.modalService.dismissAll();
      })
    }
  }

  getGallerie() {
    this.gallerieService.getGalleries().subscribe(data => {
      this.masonryItems = data[0].images;
    });
  }


  getSliders() {
    this.sliderService.getSliders().subscribe(data => {
      this.sliders = data;
      this.sliders.sort((a, b) => b.id - a.id);
    });
  }

  getInfos() {
    this.infoService.get5Last().subscribe(data => {
      this.infos = data;
      this.infos.sort((a, b) => b.id - a.id);
    });
  }

  getFlash() {
    this.flashService.getAllFlash().subscribe(data => {
      this.flashs = data.data;
      this.flashs.sort((a, b) => {return new Date(b.dateCreation.date).getTime() - new Date(a.dateCreation.date).getTime()});
    });
  }

  getBibs() {
    this.bibService.getAllBib().subscribe(data => {
      this.bibs = data.data;
      this.bibs.sort((a, b) => {return new Date(b.dateCreation.date).getTime() - new Date(a.dateCreation.date).getTime()});
    });
  }

  getActualites() {
    this.actuService.getActualites().subscribe(data => {
      this.actualites = data;
      this.getIMActu();
    });
  }

  async getIMActu() {

    await this.intraMurosService.getNews().subscribe(data => {

      data.forEach(async element => {
          this.actualites.push({
            id: element.id,
            urlImage: element.image,
            titre: element.title,
            date: {date: element.start_date, timezone_type: 3, timezone: 'Europe/Paris'},
            description: element.description,
            intra: true,
            slug: slugify(element.title),
          });
      });
    });
  }

  getEvents() {
    this.eventService.getEvents().subscribe(data => {
      this.events = data;
      this.getIMEvents();
    });
  }

  async getIMEvents() {

    await this.intraMurosService.getEvents().subscribe(data => {

      data.forEach(async element => {
        this.events.push({
          id: element.id,
          vignette: element.image,
          titre: element.title,
          dateDebut: {date: element.start_date, timezone_type: 3, timezone: 'Europe/Paris'},
          dateFin: {date: element.end_date, timezone_type: 3, timezone: 'Europe/Paris'},
          description: element.description,
          intra: true,
          slug: slugify(element.title),
        });
      });
      this.events.sort((a, b) => {return new Date(b.dateDebut.date).getTime() - new Date(a.dateDebut.date).getTime()});
    });
  }

  async getCategoryName(id: number): Promise<any> {
    let categories: any;
    let categorie: any;
    return new Promise<any>((resolve, reject) => {
      this.intraMurosService.getCategory().subscribe(data => {
        categories = data;
        categorie = categories.find((el => el.id === id));
        return resolve(categorie.name);
      });

    })
  }

  onSubmit(): void {
    if (this.newsletterForm.invalid) {
      Swal.fire({
        toast: true,
        title: 'Veuillez bien remplir tous les champs',
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        icon: 'error',
        timerProgressBar: true
      });
      return;
    } else {
      this.inscriptionService.add(this.newsletterForm.value).subscribe(data => {
        Swal.fire({
          toast: true,
          title: 'Votre inscription a bien été prise en compte',
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
          icon: 'success',
          timerProgressBar: true
        });
        this.newsletterForm.reset({
          'nom': '',
          'prenom': '',
          'mail': '',
          'terms': '',
        });
      },
      err => {
        Swal.fire({
          toast: true,
          title: 'Erreur',
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
          icon: 'error',
          timerProgressBar: true
        })
      });
    }
  }

  makeStringShorter(string: string): string {
    if (string.length > 35) {
      return string.substring(0, string.lastIndexOf(" ", 35)) + "...";
    } else {
      return string;
    }
  }

  openSlider(img: any) {
    const modalCreate = this.modalService.open(GallerieComponent, { size: 'xl' });
    modalCreate.componentInstance.img = img;
    modalCreate.componentInstance.modal = modalCreate;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
