import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { HomeComponent as HomeLayoutComponent } from './_layout/home/home.component';
import {WebsiteComponent as WebsiteLayout} from "./_layout/website/website.component";
import {ConseilComponent} from "./pages/conseil/conseil.component";
import {NewsComponent} from "./pages/news/news.component";
import {NewsDescribeComponent} from "./pages/news-describe/news-describe.component";
import {AgendaComponent} from "./pages/agenda/agenda.component";
import {AgendaDescribeComponent} from "./pages/agenda-describe/agenda-describe.component";
import {CcasComponent} from "./pages/ccas/ccas.component";
import {DailyComponent} from "./pages/daily/daily.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {ContactComponent} from "./pages/contact/contact.component";
import { ElusComponent } from './pages/ma-commune/conseil/elus/elus.component';
import { FlashComponent } from './pages/ma-commune/publications/flash/flash.component';
import { EquipementsComponent } from './pages/ma-commune/services/equipements/equipements.component';
import { PersonnelsComponent } from './pages/ma-commune/services/personnels/personnels.component';
import { BibComponent } from './pages/ma-commune/publications/bib/bib.component';
import { EvenementsComponent } from './pages/mes-loisirs/evenements/evenements.component';
import { PageIntermediaireComponent } from './pages/page-intermediaire/page-intermediaire.component';
import { BuilderPageComponent } from './components/builder-page/builder-page.component';
import { PageBuilderComponent } from './pages/page-builder/page-builder.component';
import { ArretesComponent } from './pages/ma-commune/publications/arretes/arretes.component';
import { AidesComponent } from './pages/mes-loisirs/aides/aides.component';
import { AssociationsComponent } from './pages/mes-loisirs/associations/associations.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { PolitiqueConfidentialiteComponent } from './pages/politique-confidentialite/politique-confidentialite.component';
import { CrConseilComponent } from './pages/ma-commune/conseil/cr-conseil/cr-conseil.component';
import { EnfanceEtJeunesseComponent } from './pages/enfance-et-jeunesse/enfance-et-jeunesse.component';
import { AnnuaireComponent } from './pages/mes-loisirs/associations/annuaire/annuaire.component';
import { DetailsAssociationComponent } from './pages/mes-loisirs/associations/details-association/details-association.component';
import { AgendaDescribeIntraMurosComponent } from './pages/agenda-describe-intra-muros/agenda-describe-intra-muros.component';
import { NewsImDescribeComponent } from './pages/news-im-describe/news-im-describe.component';
import { NosAinesRacontentComponent } from './pages/ma-commune/nos-aines-racontent/nos-aines-racontent.component';
const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {path: '', component: HomeComponent},
    ]
  },
  {
    path: '',
    component: WebsiteLayout,
    data: {breadcrumb: {label: 'Accueil'}},

    children: [
      {path: 'ma-commune/conseil/elus', component: ElusComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customRubrique}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ma-commune/conseil/cr-conseil', component: CrConseilComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customRubrique}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ma-commune/publications/flash-info', component: FlashComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ma-commune/publications/bib', component: BibComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ma-commune/publications/arretes-municipaux', component: ArretesComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ma-commune/publications/nos-aines-racontent', component: NosAinesRacontentComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ma-commune/publications/:rubrique/:item', component: PageBuilderComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customRubrique}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'mes-loisirs/evenements', component: EvenementsComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'mes-loisirs/evenements/:slug', component: AgendaDescribeComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'mes-loisirs/evenements/:id/:slug', component: AgendaDescribeIntraMurosComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'mon-quotidien/enfance-et-jeunesse/:rubrique', component: EnfanceEtJeunesseComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'mon-quotidien/enfance-et-jeunesse/:rubrique/:item', component: PageBuilderComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customMenu}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customSection}}',// pageTwoID Parameter value will be add
            url: ''
          },
          {
            label: '{{customRubrique}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'ccas', component: CcasComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: 'Mon quotidien',
            url: '/mon-quotidien'
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'actualites', component: NewsComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'actualites/:slug', component: NewsDescribeComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: 'Actualités',// pageTwoID Parameter value will be add
            url: '/actualites'
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'actualites/:id/:slug', component: NewsImDescribeComponent, data: {
        breadcrumb: [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: 'Actualités',// pageTwoID Parameter value will be add
            url: '/actualites'
          },
          {
            label: '{{customTitle}}',// pageTwoID Parameter value will be add
            url: ''
          }
        ]
      }},
      {path: 'contact', component: ContactComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Contact',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'mentions-legales', component: MentionsLegalesComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Mentions legales',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'confidentialite', component: PolitiqueConfidentialiteComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Politiques de confidentialité',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'flash-info', component: FlashComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Flash-Info',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'bib', component: BibComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Bib',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'arretes-municipaux', component: ArretesComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Arretes-Municipaux',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'ma-commune/services/personnels-communaux', component: PersonnelsComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Ma commune',
              url: '/ma-commune'
            },
            {
              label: 'Services',
              url: '/services'
            },
            {
              label: 'Personnels Communaux',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'ma-commune/services/equipements-communaux', component: EquipementsComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Ma commune',
              url: '/ma-commune'
            },
            {
              label: 'Services',
              url: '/services'
            },
            {
              label: 'Equipements Communaux',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'aides', component: AidesComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Aides',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'mes-loisirs/associations/annuaire-des-associations', component: AnnuaireComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Mes loisirs',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: 'Associations',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: 'Annuaire des associations',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: 'mes-loisirs/associations/annuaire-des-associations/:slug', component: DetailsAssociationComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Mes loisirs',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: 'Associations',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: 'Annuaire des associations',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: '{{customTitle}}',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: ':menu/:rubrique', component: PageIntermediaireComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: '{{customMenu}}',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: '{{customSection}}',// pageTwoID Parameter value will be add
              url: ''
            },
          ]
        }},
      {path: ':menu/:section/:rubrique', component: PageBuilderComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: '{{customMenu}}',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: '{{customSection}}',// pageTwoID Parameter value will be add
              url: ''
            },
            {
              label: '{{customRubrique}}',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
      {path: '**', component: NotFoundComponent, data: {
          breadcrumb: [
            {
              label: 'Accueil',
              url: '/'
            },
            {
              label: 'Page introuvable',// pageTwoID Parameter value will be add
              url: ''
            }
          ]
        }},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
