<section class="actualite-container mt-5">
  <div class="container">
    <div class="builder-container">
      <div class="tags"> <p *ngFor="let tag of actualite.tags">{{ tag.nom }} &nbsp;</p> | {{ actualite.dateDebut.date|date:'dd/MM/yyyy' }}</div>
      <div *ngIf="actualite.page.content !== null || actualite.page.content !== ''" [innerHTML]="actualite.page.content"></div>
      <div *ngIf="actualite.page.content === null || actualite.page.content === ''" class="under-construction">Page en construction</div>
    </div>


    <div class="form-container">
      <div class="col-lg-4 mt-lg-0 mt-3">
        <div class="filter p-5 ">
          <p class="py-2 pt-4">Filtrer les résultats par :</p>
          <form [formGroup]="categorieForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label class="mb-3">Catégorie :</label>
              <div>
                <select class="d-block w-100" formControlName="categorie">
                  <option value="" disabled selected>Selectionnez une catégorie</option>
                  <option *ngFor="let tag of tags" value="{{tag.id}}">{{tag.nom}}</option>
                </select>
              </div>
              <div>
                <button type="submit" class="btn btn-dark d-block w-100 my-4">Valider</button>
                <button (click)="openPopUpPage('actualite')" class="btn btn-outline-dark d-block w-100">PROPOSER UNE ACTUALITÉ</button>
              </div>
            </div>
          </form>
        </div>
        <img class="img-fluid mt-5 d-none d-lg-block" src="assets/icons/element-graphique-point.svg" />
      </div>
    </div>

  </div>
</section>
