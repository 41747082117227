<section class="actualite-container mt-5">
    <div class="container">
        <div class="builder-container">
            <div class="" [innerHTML]="style| safeHtml"></div>
            <div *ngIf="page?.contenu !== null || page.contenu !== ''" class="contenu" [innerHTML]="page?.contenu"></div>
            <div *ngIf="page?.contenu === null || page.contenu === ''" class="under-construction" > Page en construction</div>
        </div>

        <div class="form-container">
            <app-quick-access></app-quick-access>
        </div>

    </div>
</section>

